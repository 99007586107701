const dev = {
    STRIPE_KEY: "pk_test_UxSW9y4lq03RIfLEavS67PRa001Z1DyKjn",
    s3: {
        REGION: "us-west-2",
        BUCKET: "dev-boatpictures"
    },
    apiGateway:{
        REGION: "us-west-2",
        URL: "https://hld143sqsk.execute-api.us-west-2.amazonaws.com/dev"
    },
    cognito: {
        REGION: "us-west-2",
        USER_POOL_ID: "us-west-2_UmRXX7IEe",
        APP_CLIENT_ID: "2l64h3d280fnf025gj64hro061",
        IDENTITY_POOL_ID: "us-west-2:c9db9e66-b4db-40cc-9c03-86798bd7bac1"
    }
};

const prod = {
    STRIPE_KEY: "pk_test_UxSW9y4lq03RIfLEavS67PRa001Z1DyKjn",
    s3: {
        REGION: "us-west-2",
        BUCKET: "prod-boatpictures"
    },
    apiGateway:{
        REGION: "us-west-2",
        URL: "https://4knxwl45v6.execute-api.us-west-2.amazonaws.com/prod"
    },
    cognito: {
        REGION: "us-west-2",
        USER_POOL_ID: "us-west-2_rSVme9eRf",
        APP_CLIENT_ID: "111fdkhg35eq5upk138g348801",
        IDENTITY_POOL_ID: "us-west-2:ac3f4a6b-75dc-4c79-ac1a-09347937baf3"
    }
};

const config = process.env.REACT_APP_STAGE === 'prod'
? prod
: dev;

export default {

    MAX_ATTACHMENT_SIZE: 5000000,
    ...config
}