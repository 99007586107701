import React, { useState, useEffect } from "react";
import Routes from "./Routes";
import "./App.css";
import {withRouter } from "react-router-dom";
import { Navbar, Nav, NavItem, NavDropdown } from "react-bootstrap";
import { LinkContainer } from "react-router-bootstrap";
import { Auth } from "aws-amplify";
import brand from "./bannerlogo.svg";

function App(props) {
  const [isAuthenticated, userHasAuthenticated] = useState(false);
  const [isAuthenticating, setIsAuthenticating] = useState(true);
  const [expanded, setExpanded] = useState(false);

  useEffect(() => {
    onLoad();
  }, []);

  async function onLoad() {
    try {
      await Auth.currentSession();
      userHasAuthenticated(true);
    } catch (e) {
      if (e !== "No current user") {
        alert(e);
      }
    }
    setIsAuthenticating(false);
  }

  async function handleLogout() {
    await Auth.signOut();
    userHasAuthenticated(false);
    props.history.push("/login");
  }

  return (
    !isAuthenticating && (
      <div className="App">
        <div id="navbar-div">
          <Navbar expanded={expanded} expand="lg" collapseOnSelect>
            <LinkContainer to="/">
            <a className="brand-image-link" href="/">
              <img className="brand-image" src={brand} alt="" />
            </a>
            </LinkContainer>
            <Navbar.Toggle onClick={() => setExpanded(expanded ? false : "expanded")}/>
            <Navbar.Collapse style={{ justifyContent: "right" }}>
              <Nav>
                <LinkContainer onClick={() => setExpanded(false)} to="/permits">
                  <NavItem >Permits</NavItem>
                </LinkContainer>
                <LinkContainer onClick={() => setExpanded(false)} to="/boats">
                  <NavItem>Boats</NavItem>
                </LinkContainer>
                <LinkContainer onClick={() => setExpanded(false)} to="/misc">
                  <NavItem>Misc.</NavItem>
                </LinkContainer>
                <LinkContainer to="/sell">
                  <NavDropdown title="Sell">
                    <LinkContainer onClick={() => setExpanded(false)} to="/boats/new">
                      <NavDropdown.Item style={{fontweight:"bolder"}}>Boat</NavDropdown.Item>
                    </LinkContainer>
                    <LinkContainer onClick={() => setExpanded(false)} to="/permits/new">
                      <NavDropdown.Item style={{fontWeight:"bolder"}}>Permit</NavDropdown.Item>
                    </LinkContainer>
                    <LinkContainer onClick={() => setExpanded(false)} to="/new/boat-permit-package">
                      <NavDropdown.Item style={{fontWeight: 'bolder'}}>Boat + Permit</NavDropdown.Item>
                    </LinkContainer>
                    <LinkContainer onClick={() => setExpanded(false)} to="/misc/new">
                      <NavDropdown.Item style={{fontWeight:"bolder"}}>Miscellaneous</NavDropdown.Item>
                    </LinkContainer>
                  </NavDropdown>
                </LinkContainer>
                <LinkContainer onClick={() => setExpanded(false)} to="/about">
                  <NavItem>About Us</NavItem>
                </LinkContainer>
                {isAuthenticated ? (
                  <div className="logged-in-options">
                    <LinkContainer onClick={() => setExpanded(false)} to="/account">
                      <NavItem>My Account</NavItem>
                    </LinkContainer>
                    <NavItem onClick={handleLogout}>Logout</NavItem>
                  </div>
                ) : (
                  <React.Fragment>
                    <LinkContainer onClick={() => setExpanded(false)} to="/signup">
                      <NavItem>Signup</NavItem>
                    </LinkContainer>
                    <LinkContainer onClick={() => setExpanded(false)} to="/login">
                      <NavItem>Login</NavItem>
                    </LinkContainer>
                  </React.Fragment>
                )}
              </Nav>
            </Navbar.Collapse>
          </Navbar>
        </div>
        <Routes appProps={{ isAuthenticated, userHasAuthenticated }} />
        <Navbar className="footer">
          <a className="brand-image-link" href="/">
            <img className="brand-image" src={brand} alt="" />
          </a>
        </Navbar>
      </div>
    )
  );
}

export default withRouter(App);
