import React, { Suspense, lazy } from "react";
import { Route, Switch } from "react-router-dom";
import AppliedRoute from "./components/AppliedRoute";
import {BsArrowRepeat} from "react-icons/bs";
// import AuthenticatedRoute from "./components/AuthenticatedRoute";
const AuthenticatedRoute = lazy (() => import("./components/AuthenticatedRoute"));
// import UnauthenticatedRoute from "./components/UnauthenticatedRoute";
const UnauthenticatedRoute = lazy (() => import("./components/UnauthenticatedRoute"));
// import Home from "./containers/Home";
const Home = lazy (() => import("./containers/Home"));
const Boats = lazy (() => import("./containers/Boats" /*webpackChunkName: 'Boats' */));
// import Login from "./containers/Login";
const Login = lazy (() => import("./containers/Login"));
// import Signup from "./containers/Signup";
const Signup  = lazy (() => import("./containers/Signup"));
// import Account from "./containers/Account";
const Account = lazy (() => import("./containers/Account"));
// import Boat from "./containers/Boat";
const Boat = lazy (() => import("./containers/Boat"));
// import NewBoat from "./containers/NewBoat";
const NewBoat  = lazy (() => import("./containers/NewBoat"));
// import PreviewNewBoatPost from "./containers/PreviewNewBoatPost";
const PreviewNewBoatPost  = lazy (() => import("./containers/PreviewNewBoatPost"));
// import EditBoat from "./containers/EditBoat";
const EditBoat  = lazy (() => import("./containers/EditBoat"));
// import PreviewBoatUpdate from "./containers/PreviewBoatUpdate";
const PreviewBoatUpdate  = lazy (() => import("./containers/PreviewBoatUpdate"));
// import UpdateBoat from "./containers/UpdateBoat";
const UpdateBoat  = lazy (() => import("./containers/UpdateBoat"));
// import PostSuccess from "./containers/PostSuccess";
const PostSuccess = lazy (() => import("./containers/PostSuccess"));
// import UpdateSuccess from "./containers/UpdateSuccess";
const UpdateSuccess = lazy (() => import("./containers/UpdateSuccess"));
// import Permits from "./containers/Permits";
const Permits  = lazy (() => import("./containers/Permits"));
// import NewPermit from "./containers/NewPermit";
const NewPermit = lazy (() => import("./containers/NewPermit"));
// import EditPermit from "./containers/UpdatePermit";
const EditPermit = lazy (() => import("./containers/UpdatePermit"));
// import PreviewPermitPost from "./containers/PreviewPermitPost";
const PreviewPermitPost  = lazy (() => import("./containers/PreviewPermitPost"));
// import UpdatePermit from "./containers/UpdatePermit";
const UpdatePermit  = lazy (() => import("./containers/UpdatePermit"));
// import BoatPermitPackage from "./containers/BoatPermitPackage";
const BoatPermitPackage = lazy (() => import("./containers/BoatPermitPackage"));
import ErrorBoundary from "./components/ErrorBoundary";
// const ErrorBoundary = lazy (() => import("./components/ErrorBoundary"));
import NotFound from "./containers/NotFound";
// import NewPermitListTable from "./containers/NewPermitListTable";
// import MiscListings from "./containers/MiscListings";
const MiscListings = lazy (() => import("./containers/MiscListings"));
// import NewMiscPost from "./containers/NewMiscPost";
const NewMiscPost  = lazy (() => import("./containers/NewMiscPost"));
// import PreviewMiscPost from "./containers/PreviewMiscPost";
const PreviewMiscPost  = lazy (() => import("./containers/PreviewMiscPost"));
// import EditNewMisc from "./containers/EditNewMiscPost";
const EditNewMisc  = lazy (() => import("./containers/EditNewMiscPost"));
// import UpdateMisc from "./containers/UpdateMisc";
const UpdateMisc  = lazy (() => import("./containers/UpdateMisc"));
// import PreviewMiscUpdate from "./containers/PreviewMiscUpdate"
const PreviewMiscUpdate  = lazy (() => import("./containers/PreviewMiscUpdate"));
// import Miscellaneous from "./containers/Miscellaneous";
const Miscellaneous  = lazy (() => import("./containers/Miscellaneous"));
// import ResetPassword from "./containers/ResetPassword";
const ResetPassword  = lazy (() => import("./containers/ResetPassword"));
export default function Routes({ appProps }) {
  return (
    <Suspense fallback={<div className="body"><BsArrowRepeat isLoading={true}/></div>}>
    <Switch>
      {/* <ErrorBoundary> */}
        <AppliedRoute
          path="/"
          exact
          component={Home}
          appProps={appProps}
        />

        <AppliedRoute
          path="/Boats"
          exact
          component={Boats}
          appProps={appProps}
        />

        <UnauthenticatedRoute
          path="/login"
          exact
          component={Login}
          appProps={appProps}
        />
        <UnauthenticatedRoute
          path="/login/reset"
          exact
          component={ResetPassword}
          appProps={appProps}
        />
        <UnauthenticatedRoute
          path="/signup"
          exact
          component={Signup}
          appProps={appProps}
        />
        <AuthenticatedRoute
          path="/account"
          exact
          component={Account}
          appProps={appProps}
        />
        <AuthenticatedRoute
          path="/boats/new"
          exact
          component={NewBoat}
          appProps={appProps}
        />
        <AuthenticatedRoute
          path="/boats/new/preview"
          exact
          component={PreviewNewBoatPost}
          appProps={appProps}
        />
        <AuthenticatedRoute
          path="/boats/new/success"
          exact
          component={PostSuccess}
          appProps={appProps}
        />
        <AuthenticatedRoute
          path="/boats/new/edit"
          exact
          component={EditBoat}
          appProps={appProps}
        />
        <AppliedRoute
          path="/boats/browse/:yrManuPriceDate"
          exact
          component={Boat}
          appProps={appProps}
        />
        <AuthenticatedRoute
          path="/userboats/update"
          exact
          component={UpdateBoat}
          appProps={appProps}
        />
        <AuthenticatedRoute
          path="/userboats/update/preview"
          exact
          component={PreviewBoatUpdate}
          appProps={appProps}
        />
        <AuthenticatedRoute
          path="/userboats/update/success"
          exact
          component={UpdateSuccess}
          appProps={appProps}
        />
        <AuthenticatedRoute
          path="/userpermits/update"
          exact
          component={UpdatePermit}
          appProps={appProps}
        />
        <AppliedRoute
          path="/permits"
          exact
          component={Permits}
          appProps={appProps}
        />
        <AuthenticatedRoute
          path="/permits/new"
          exact
          component={NewPermit}
          appProps={appProps}
        />
        {/* <AppliedRoute
          path="/permits/new/list"
          exact
          component={NewPermitListTable}
          appProps={appProps}
        /> */}
        <AuthenticatedRoute
          path="/permits/new/preview"
          exact
          component={PreviewPermitPost}
          appProps={appProps}
        />
        <AuthenticatedRoute
          path="/permits/new/success"
          exact
          component={PostSuccess}
          appProps={appProps}
        />
        <AuthenticatedRoute
          path="/permits/new/edit"
          exact
          component={EditPermit}
          appProps={appProps}
        />
        <AuthenticatedRoute
          path="/userpermits/update/preview"
          exact
          component={PreviewPermitPost}
          appProps={appProps}
        />
        <AppliedRoute
          path="/misc"
          exact
          component={MiscListings}
          appProps={appProps}
        />
        <AuthenticatedRoute
          path="/misc/new"
          exact
          component={NewMiscPost}
          appProps={appProps}
        />
        <AuthenticatedRoute
          path="/misc/new/preview"
          exact
          component={PreviewMiscPost}
          appProps={appProps}
        />
        <AuthenticatedRoute
          path="/misc/new/edit"
          exact
          component={EditNewMisc}
          appProps={appProps}
        />
        <AuthenticatedRoute
          path="/usermisc/update"
          exact
          component={UpdateMisc}
          appProps={appProps}
        />
        <AuthenticatedRoute
          path="/usermisc/update/preview"
          exact
          component={PreviewMiscUpdate}
          appProps={appProps}
        />
        <AuthenticatedRoute
          path="/misc/new/success"
          exact
          appProps={appProps}
          component={PostSuccess}
        />
        <AppliedRoute
          path="/misc/browse/:postId"
          exact
          component={Miscellaneous}
        />
        <AuthenticatedRoute
          path="/new/boat-permit-package"
          appProps={appProps}
          exact
          component={BoatPermitPackage}
          />
      {/* </ErrorBoundary> */}
      <Route component={NotFound} />
    </Switch>
    </Suspense>
  );
}
